import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import { StaticQuery, graphql } from "gatsby"

const HomeOfTheAtlanticSalmon = ({ contentField }) => {
  const lang = "it"
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout
      lang={lang}
      translationEN="/home-of-the-atlantic-salmon/"
      translationDE="/de/hemat-des-atlantischen-lachses/ "
      translationES="/es/hogar-del-salmon-del-atlantico/"
      translationFR="/fr/foyer-du-saumon-de-l-atlantique/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="LA CASA NATURALE DEL SALMONE ATLANTICO"
        image={require("../../assets/images/home-of-the-atlantic-salmon-back.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{ __html: contentField.content }}
        />
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNjI3" }) {
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <HomeOfTheAtlanticSalmon {...data} />}
  />
)
