import React from "react"

const BigHeader = props => {
  return (
    <div
      className={`subpage-header${
        props.image ? "" : " subpage-header__default"
      }`}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <h1 className="subpage-header__title">{props.title}</h1>
    </div>
  )
}

export default BigHeader
